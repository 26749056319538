import { createTheme, PaletteMode } from '@mui/material/styles';

const buildTheme = (mode: PaletteMode) =>
    createTheme({
        palette: {
            mode,
            background: {
                default: '#111111'
            },
            text: {
                primary: '#ffffff'
            }
        },
        spacing: 4,
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif'
            ].join(',')
        }
    });

export const lightTheme = buildTheme('light')
export const darkTheme = buildTheme('dark')

export const iconColour = '#FAE3B3'