import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Box, CssBaseline, Divider, Link, Stack, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import { darkTheme, iconColour, lightTheme } from './theme';

interface ServiceItemProps {
  icon: string
  titleTop: string
  titleBottom: string
}

function ServiceItem({icon, titleTop, titleBottom}: ServiceItemProps) {
  return <Stack direction='row' alignItems='center' spacing='2vw'>
    <Box component='img' src={icon} height='5vh' />
    <Stack>
      <Typography fontSize='2.1vh' lineHeight='2.5vh'>{titleTop}</Typography>
      <Typography fontSize='2.1vh' lineHeight='2.5vh' fontWeight='bold'>{titleBottom}</Typography>
    </Stack>
  </Stack>
}

function Content() {
  return <Stack alignItems='center' justifyContent='center' width='100vw' height='100vh'>
    <Stack width='130vh' height='80vh'>
      <Stack position='relative' width='100%' height='70%'>
        <Box component='img' src='collaboration.jpeg' position='absolute' height='100%' right='0' sx={{opacity: 0.8}} />
        <Stack position='absolute' height='100%' padding='10px'>
          <Stack height='40%' padding='10px'>
          <Typography variant='h6' fontWeight='medium' color={iconColour}>
              <Stack direction='row' alignItems='center'>
                <Box component='img' src='icon.png' height='1em' marginRight='0.5em' />
                tinetree
              </Stack>
            </Typography>
          </Stack>
          <Stack height='60%' justifyContent='center'>
            <Typography fontSize='7vh' lineHeight='9vh' fontWeight='bold'>Engineering Excellence</Typography>
            <Typography fontSize='7vh' lineHeight='9vh' fontWeight='bold'>Through Meaningful</Typography>
            <Typography fontSize='7vh' lineHeight='9vh' fontWeight='bold'>Relationships</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack height='30%' width='100%'>
        <Stack height='50%'></Stack>
        <Divider sx={{backgroundColor: 'white'}}/>
        <Stack height='50%' direction='row' justifyContent='space-between' width='100%'>
          <ServiceItem icon='cloud-icon.png' titleTop='Cloud Platform' titleBottom='Engineering'></ServiceItem>
          <ServiceItem icon='full-stack-icon.png' titleTop='Full Stack Software' titleBottom='Transformation'></ServiceItem>
          <ServiceItem icon='hybrid-icon.png' titleTop='Hybrid Intelligence' titleBottom='Architecture'></ServiceItem>
          <Link
            underline='hover' rel='noopener' target='_blank'
            sx={{backgroundColor: '#0077B7', color: 'white', height: '40px', padding: '8px', borderRadius: '8px', marginTop: '22px'}}
            href='https://www.linkedin.com/in/dr-mykola-rozhok-53182619/'
          >
            <Stack direction='row' alignItems='center'>
              Contact
              <Box component='img' src='linkedin-icon.png' height='20px' paddingLeft='6px'/>
            </Stack>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
}

function Index() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  return (
    <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
      <CssBaseline/>
      <Content/>
    </ThemeProvider>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

reportWebVitals();
